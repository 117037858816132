import React from "react"
import Management from "../../components/about/Management"
import PageHeader from "../../components/common/PageHeader"
import Layout from "../../components/layout"
import { Tab } from "@headlessui/react"
import BoardOfDirectors from "../../components/about/BoardOfDirectors"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HeaderFeaturedImage from "../../components/common/HeaderFeaturedImage"
import SEO from "../../components/seo"
import Auditors from "../../components/financial/Auditors"
import GeneralMeetings from "../../components/financial/GeneralMeetings"
import ArticlesOfAssociation from "../../components/financial/ArticlesOfAssociation"

const tabs = [
  { name: "Auditors", href: "#", current: true },
  { name: "Management", href: "#", current: true },
  { name: "Board Of Directors", href: "#", current: false },
  { name: "Articles of Association", href: "#", current: false },
  { name: "General Meetings", href: "#", current: false },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function CorporateGovernancePage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 46 }) {
        content
        title
        auditors {
          auditorsInfo {
            basicInfoAuditors
          }
        }
        generalMeetings {
          gmInfo {
            basicInfoGm
          }
        }
        aoc {
          articlesOfAssociation {
            basicInfoAoa
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const content = data.wpPage.content
  const { title, featuredImage, auditors, generalMeetings, aoc } = data.wpPage
  const img = data.wpPage.featuredImage.node.localFile.childImageSharp
  return (
    <Layout>
      <SEO title={title} />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div className="max-w-screen-2xl mx-auto py-8 lg:py-16 px-4 lg:px-8">
          <div className="flex flex-col gap-8">
            {!featuredImage ? <PageHeader text="About us" /> : null}
            {/* Introducton */}
            <div className="max-w-3xl  mx-auto flex flex-col xl:flex-row gap-4 lg:gap-8 items-center">
              <div className="flex-1">
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  className="wp-content"
                />
              </div>
              {/* <div className="flex-1">
                <GatsbyImage image={getImage(img)} />
              </div> */}
            </div>
            <div className=" pg-4 lg:p-8">
              <div className="max-w-6xl  mx-auto flex flex-col justify-center items-center gap-8">
                <Tab.Group>
                  <Tab.List
                    as="div"
                    className=" flex flex-row gap-4 flex-wrap justify-center lg:justify-start"
                  >
                    {tabs.map(tab => {
                      return (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              " rounded-lg py-2 md::py-2.5 px-2 md:px-3 text-primary text-base sm:text-lg md:text-xl font-bold",
                              " focus:outline-none",
                              selected ? "bg-primary/10  " : ""
                            )
                          }
                        >
                          {tab.name}
                        </Tab>
                      )
                    })}
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <Auditors auditors={auditors} />
                    </Tab.Panel>
                    <Tab.Panel>
                      <Management />
                    </Tab.Panel>
                    <Tab.Panel>
                      <BoardOfDirectors />
                    </Tab.Panel>
                    <Tab.Panel>
                      <ArticlesOfAssociation aoc={aoc} />
                    </Tab.Panel>
                    <Tab.Panel>
                      <GeneralMeetings generalMeetings={generalMeetings} />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
          {/* Management */}
        </div>
      </div>
    </Layout>
  )
}
