import React from "react"

export default function GeneralMeetings({ generalMeetings }) {
  const { gmInfo } = generalMeetings
  return (
    <div>
      {" "}
      <div
        className="max-w-3xl mx-auto
      "
      >
        <div
          dangerouslySetInnerHTML={{ __html: gmInfo.basicInfoGm }}
          className="wp-content"
        />
      </div>
    </div>
  )
}
