import React from "react"

export default function ArticlesOfAssociation({ aoc }) {
  const { articlesOfAssociation } = aoc
  return (
    <div>
      {" "}
      <div
        className="max-w-3xl mx-auto
      "
      >
        <div
          dangerouslySetInnerHTML={{
            __html: articlesOfAssociation?.basicInfoAoa,
          }}
          className="wp-content"
        />
      </div>
    </div>
  )
}
