import React from "react"

export default function Auditors({ auditors }) {
  const { auditorsInfo } = auditors
  return (
    <div>
      {" "}
      <div
        className="max-w-3xl mx-auto
      "
      >
        <div
          dangerouslySetInnerHTML={{ __html: auditorsInfo?.basicInfoAuditors }}
          className="wp-content"
        />
      </div>
    </div>
  )
}
